import axios from 'axios';

const { VUE_APP_API_AUTH_URL, VUE_APP_AUTH_CLIENT_ID, VUE_APP_API_URL } = process.env;
import Vue from 'vue';

export const getTokenOnboardee = (user) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'password');
  payload.append('scope', 'offline_access onboarding');
  payload.append('role', 'onboardee');
  payload.append('subdomain', user.subdomain);
  payload.append('username', user.email);
  payload.append('password', user.password);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getTokenMfa = (user, mfa) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'mfa_code');
  payload.append('scope', 'offline_access onboarding');
  payload.append('role', 'onboardee');
  payload.append('subdomain', user.subdomain);
  payload.append('mfa_code', mfa.code);
  payload.append('mfa_type', mfa.type);
  payload.append('token', mfa.token);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getTokenTemplate = (token, templateId) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'template');
  payload.append('scope', 'onboarding');
  payload.append('template_id', templateId);
  payload.append('token', token);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const refreshToken = (user, token, refreshToken) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'refresh_token');
  payload.append('token', token);
  payload.append('refresh_token', refreshToken);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const logout = (refresh_token) => {
  const payloadRefreshToken = new URLSearchParams();
  payloadRefreshToken.append('token', refresh_token);
  payloadRefreshToken.append('token_type_hint', 'refresh_token');
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/revocation`, payloadRefreshToken, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getUserMfaConfigurations = (userId) => {
  return Vue.prototype.$http.get(`${VUE_APP_API_AUTH_URL}/multi-factor-authentication/users/${userId}`);
};

export const getCompanyMfaConfigurations = (companyId) => {
  return Vue.prototype.$http.get(`${VUE_APP_API_AUTH_URL}/multi-factor-authentication/companies/${companyId}/setups`);
};

export const getUserMfaSetup = (userId, providerType) => {
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/multi-factor-authentication/users/${userId}/setups`, {
    providerType
  });
};

export const confirmUserMfaSetup = (userId, config) => {
  return Vue.prototype.$http.put(
    `${VUE_APP_API_AUTH_URL}/multi-factor-authentication/users/${userId}/setups/${config.id}`,
    {
      providerType: config.providerType,
      code: config.code,
      scope: 'onboarding',
      role: config.role
    }
  );
};

export const deleteUserMfaConfiguration = (userId, configId) => {
  return Vue.prototype.$http.delete(
    `${VUE_APP_API_AUTH_URL}/multi-factor-authentication/users/${userId}/setups/${configId}`
  );
};

export const checkSyncedStatus = (companyId, email) => {
  return Vue.prototype.$http.get(
    `${VUE_APP_API_URL}/v2/companies/${companyId}/users/sync-status?email=${encodeURIComponent(email)}`
  );
};

export const getTalmundoTokenFromTalentechToken = (talentechToken, companyId) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'talentech_id');
  payload.append('scope', 'offline_access onboarding');
  payload.append('role', 'onboardee');
  payload.append('company_id', companyId);
  payload.append('token', talentechToken);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getTalmundoTokenFromAdminToken = (adminToken, companyId) => {
  const payload = new URLSearchParams();
  payload.append('grant_type', 'admin_to_onboardee');
  payload.append('scope', 'offline_access onboarding');
  payload.append('role', 'onboardee');
  payload.append('company_id', companyId);
  payload.append('token', adminToken);
  return Vue.prototype.$http.post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
    headers: {
      Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
